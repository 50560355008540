body {
  background-color: #F1F5F6;
  color: #27566B;
}

a {
  color: #27566B;
}

a:hover {
  color: #27566B;
  text-decoration: underline #D49595;
}

.pink {
  color: #D49595;
}